import React from "react";
import { Container } from "neetoui/layouts";
import { Callout } from "neetoui";

const App = () => (
  <Container>
    <div className="neeto-ui-bg-gray-300 w-full h-full flex-1">
      <div className="flex justify-center items-center h-full">
        <Callout style="info">
          No application is deployed at this URL. 404
        </Callout>
      </div>
    </div>
  </Container>
);

export default App;
